<template>
  <section>
    <b-container>
      <b-row>
        <b-col class="text-center">
          <h3 class="mb-5">{{ payload.title }}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="accordion d-lg-flex flex-lg-row">
            <div v-for="(e, index) in payload.content" :key="index" class="d-lg-flex flex-lg-row accordion_box">
              <div v-if="!ac[index]" class="accordion-header d-flex" :id="'ach_' + index" v-b-toggle="'ac-' + index">
                <h4>{{ e.title }}</h4>
              </div>
              <b-collapse v-model="ac[index]" accordion="ac" class="accordion-content" :id="'ac-' + index" :visible="index === 0">
                <b-row>
                  <b-col>
                    <h4 class="mb-4">{{ e.title }}</h4>
                  </b-col>
                </b-row>
                <div class="scroll-wrapper">
                  <div v-for="(boxes, b_index) in getBoxes(e.boxes)" :key="'b_'+b_index" class="content_box_wrapper d-flex align-items-stretch">
                    <b-row no-gutters class="align-items-stretch">
                      <b-col cols="6" v-for="(cb, cb_index) in boxes" :key="'cb_'+cb_index" class="content_box_container h-50">
                        <div class="content_box">
                          <div v-if="cb.content" class="number">0{{ cb_index + 1 + b_index * 4 }}.</div>
                          <div v-html="cb.content"></div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="d-block d-lg-none">
                  <b-row no-gutters v-for="(boxes, b_index) in getBoxes(e.boxes)" :key="'b_'+b_index" class="content_box_wrapper">
                      <b-col lg="6" v-for="(cb, cb_index) in boxes" :key="'cb_'+cb_index" no-gutters class="mb-3">
                        <div class="content_box">
                          <div v-if="cb.content" class="number">0{{ cb_index + 1 + b_index * 4 }}.</div>
                          <div v-html="cb.content"></div>
                        </div>
                      </b-col>
                  </b-row>
                </div>
              </b-collapse>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>

export default {
  name: "FancyAccordion",
  props: ['payload'],
  data() {
    return {
      ac: {
        0: true,
        1: false,
        2: false,
      },
      settings: {
        "dots": false,
        "infinite": false,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "adaptiveHeight": true,
      },
    }
  },
  methods: {
    getBoxes(boxes) {
      let i, chunkBoxes=[], chunk = 4;
      for (i = 0; i < boxes.length; i += chunk) {
        let chunkBox = boxes.slice(i, i + chunk)
        for(let j = chunkBox.length; j < 4;j++){
          chunkBox.push({content: ''});
        }
        chunkBoxes.push(chunkBox);
      }
      return chunkBoxes;
    }
  }
}
</script>

<style scoped lang="scss">
$border-cross-color: $white;

section {
  margin-bottom: 192px;
  margin-top: 97px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .accordion {height: 880px}
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .accordion {height: 665px}
}
@media (min-width: 1400px) {
  .accordion {height: 568px}
}

@media (min-width: 992px) {
  .accordion {
    .accordion_box:nth-child(3) {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    .accordion-content {
      padding: 2rem 3rem !important;
      .content_box_wrapper {
        &:not(:last-of-type) {
          border-right: 1px solid $border-cross-color;
        }
      }
      .content_box_container {
        padding: 1rem;
        &:nth-child(1) {
          padding-top: 0;
          border-right: 1px solid $border-cross-color;
          border-bottom: 1px solid $border-cross-color;
        }
        &:nth-child(2) {
          padding-top: 0;
          border-bottom: 1px solid $border-cross-color;
        }
        &:nth-child(3) {
          border-right: 1px solid $border-cross-color;
        }
      }
    }
  }
    .accordion-header h4 {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        margin: 0;
        padding: 50px 64px;
        display: flex;
    }
  .scroll-wrapper {
    display: -webkit-box;
    height: calc(100% - 64px);
  }
}
@media (max-width: 991px) {
  .scroll-wrapper {
    display: none;
  }
  .accordion-header h4 {
    padding: 24px 16px;
  }
}


h4 {
  font-size: 2rem;
}
.accordion {
  color: $white;
  .accordion_box {
    &:nth-child(1) { background: #007CC2; }
    &:nth-child(2) { background: #FCC157; }
    &:nth-child(3) {
      background: #FF4337;
    }

    .collapsing {
      display: none;
      transition: none !important;
    }
  }
  .accordion-content {
    padding: 24px 16px;
    .content_box {
      font-size: 14px !important;
      font-weight: 500;
      line-height: 23px;
      p {
        font-size: 12px !important;
        font-weight: 500;
        line-height: 23px;
      }
      .number {
        margin: 0 auto;
        margin-bottom: 0.5rem;
        font-family: 'Samsung Sharp Sans';
        text-align: center;
        width: 50px;
        height: 50px;
        border: 1px solid white;
        border-radius: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 50px;
      }
    }
  }
}
</style>
