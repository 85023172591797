<template>
  <section>
    <b-container>
      <b-row>
        <b-col class="text-center">
          <h3>{{ payload.title }}</h3>
        </b-col>
      </b-row>
    </b-container>
    <div class="d-none d-lg-block">
      <b-container>
        <b-row>
          <b-col v-for="(box,index) in payload.phases" :key="'phase_top_' + index" class="text-center">
            <div v-if="index % 2 ===  0" v-html="box.description"></div>
          </b-col>
        </b-row>
      </b-container>
      <div class="hr_container">
        <b-container>
          <b-row>
            <b-col v-for="(_,index) in payload.phases" :key="'phase_num_' + index" class="text-center">
              <div class="number">0{{ index + 1}}.</div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container>
        <b-row>
          <b-col v-for="(box,index) in payload.phases" :key="'phase_bottom_' + index" class="text-center mt-4">
            <div v-if="index % 2 ===  1" v-html="box.description"></div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- tablet -->
    <div class="d-lg-none d-sm-block d-none">
      <b-container>
        <b-row>
          <b-col>
            <b-row class="align-content-around h-100">
              <b-col cols="12" v-for="(box,index) in payload.phases" :key="'phase_top_' + index" class="text-center">
                <div v-if="index % 2 ===  1" v-html="box.description"></div>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="vr_container">
            <b-row class="align-content-around h-100">
              <b-col cols="12" v-for="(_,index) in payload.phases" :key="'phase_num_' + index" class="text-center">
                <div class="number">0{{ index + 1}}.</div>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row class="align-content-around h-100">
              <b-col cols="12" v-for="(box,index) in payload.phases" :key="'phase_bottom_' + index" class="text-center mt-4">
                <div v-if="index % 2 ===  0" v-html="box.description"></div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- mobile -->
    <div class="d-sm-none d-block">
      <b-container>
        <b-row class="align-content-around h-100">
          <b-col cols="12" v-for="(box,index) in payload.phases" :key="'phase_top_' + index" class="text-center mb-3">
            <div v-html="box.description"></div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>
export default {
  name: "Timeline",
  props: ['payload']
}
</script>

<style scoped lang="scss">
section {
  margin-top: 96px;
  margin-bottom: 96px;
}
h3 {
  margin-bottom: 5rem;
}
.hr_container {
  &:after {
    display: block;
    content: ' ';
    width: 100%;
    height: 1px;
    background: $black;
    transform: translateY(-33px);
  }
}
.vr_container {
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    content: ' ';
    height: 100%;
    width: 1px;
    background: $black;
  }
}
.number {
  background: $white;
  position: relative;;
  z-index: 1;
  width: 64px;
  height: 64px;
  line-height: 64px;
  border: 1px solid $black;
  border-radius: 100%;
  font-family: Samsung Sharp Sans;
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
}
</style>