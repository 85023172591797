<template>
  <section>
    <b-container>
      <b-row>
        <b-col class="text-center">
          <h3 class="mb-5">{{ payload.title }}</h3>
        </b-col>
      </b-row>
      <b-row class="justify-content-between">
        <b-col sm="12" md="6" lg="4" class="mb-5" v-for="(c,i) in payload.cols" :key="i" style="max-width: 340px">
          <h5 class="mb-4 pb-1 position-relative">{{ c.title }}</h5>
          <div v-html="c.content"></div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "TextColumns",
  props: ['payload']
}
</script>

<style scoped lang="scss">
  section {
    background: #e5e5e5;
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;

    &:after {
      content: ' ';
      background: $black;
      display: block;
      position: absolute;
      width: 98px;
      height: 1px;
      left: 0px;
      bottom: 0;
    }
  }
</style>