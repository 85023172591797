<template>
    <section class="logo-gallery">
        <b-container>
            <b-row v-if="payload.title">
                <b-col class="text-center">
                    <h3 class="mb-5">{{ payload.title }}</h3>
                </b-col>
            </b-row>
            <b-row v-if="payload.gallery.length > 0">
              <b-col lg="10" offset-lg="1">
                <b-row class="align-items-center">
                  <b-col class="mb-5" v-for="(img, i) in payload.gallery" :key="i">
                    <picture>
                      <img :src="img.url"/>
                    </picture>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="payload.gallery_2.length > 0">
              <b-col lg="10" offset-lg="1">
                <b-row class="align-items-center">
                  <b-col class="mb-5" v-for="(img, i) in payload.gallery_2" :key="'g2_'+i">
                    <picture>
                      <img :src="img.url"/>
                    </picture>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "LogoGallery",
        props: ['payload'],
    }
</script>

<style lang="scss">
    section.logo-gallery{
        margin-bottom: 180px;
        margin-top: 180px;

        picture {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;

            img{
                width: 100%;
                height: 100%;
                max-width: 160px;
                max-height: 64px;
                object-fit: contain;

                @include media-breakpoint-up(md){
                    max-height: 80px;
                }
            
                @include media-breakpoint-up(lg){
                    max-width: 432px;
                    max-height: 74px;
                }
            }
        }
    }
</style>