<template>
  <section class="faq">
    <b-container>
      <b-row v-if="payload.title">
        <b-col class="text-center">
          <h3>{{ payload.title }}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="accordion" role="tablist">
            <div v-for="(qa, index) in payload.faq" :key="index" no-body>
              <div class="d-flex flex-row justify-content-between border-bottom pt-5" v-b-toggle="'accordion-' + index">
                <h4 class="mb-3">{{ qa.question }}</h4>
                <div class="plus">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="12.4287" y1="8.99205e-08" x2="12.4287" y2="24" stroke="black" stroke-width="4"/>
                    <line x1="24" y1="12" x2="-1.74846e-07" y2="12" stroke="black" stroke-width="4"/>
                  </svg>
                </div>
                <div class="minus">
                  <svg width="24" height="4" viewBox="0 0 24 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="24" y1="2" x2="-1.74846e-07" y2="2" stroke="black" stroke-width="4"/>
                  </svg>
                </div>
              </div>
              <b-collapse :id="'accordion-' + index" :visible="index === 0 ? true : false" accordion="my-accordion" role="tabpanel">
                <div class="content mt-5" v-html="qa.answer"></div>
              </b-collapse>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Faq",
  props: ['payload']
}
</script>

<style scoped lang="scss">
.faq {
  margin-top: 180px;
  margin-bottom: 180px;
}

.collapsed {
  .plus { display: block; }
  .minus { display: none; }
}
.not-collapsed {
  .plus { display: none; }
  .minus { display: block; }
}

.d-flex {
  border-color: #C5C5C5;
}

h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}

.content {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}
</style>