<template>
    <section>
        <b-container>
            <div v-if="payload.documents.length > 0">
                <b-row>
                    <b-col class="text-center mb-5">
                        <h3 v-if="payload.title_docs" class="mr-4">{{ payload.title_docs }}</h3>
                    </b-col>
                </b-row>
                <div v-if="payload.documents.length > 0">
                    <b-row v-for="(content, index) in payload.documents" :key="index" class="mt-5">
                        <b-col cols="12">
                            <article class="card-file">
                                <div>
                                    <img :src="content.img.url" alt="Materiały do pobrania Solve For Tomorrow" class="image" />
                                </div>
                                <div class="ml-4 box-content">
                                    <div>
                                        <h5 class="card-title mt-4 mb-0 mr-4">
                                            {{content.title}}
                                        </h5>
                                        <p v-html="content.description" class="mt-3 mr-4" />
                                    </div>
                                    <div class="box-icon">
                                        <a :href="content.file" target="_blank" download>
                                            <DownloadIcon class="download-icon" />
                                        </a>
                                    </div>
                                </div>
                            </article>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div v-if="payload.video.length > 0">
                <b-row :class="[payload.documents.length > 0 ? 'video-heading-big' : 'video-heading-small']">
                    <b-col class="text-center mb-5">
                        <h3 v-if="payload.title_video">{{ payload.title_video }}</h3>
                    </b-col>
                </b-row>
                <div v-if="payload.video.length > 0">
                    <b-row v-for="(content, index) in payload.video" :key="index" class="mt-5">
                        <b-col cols="12">
                            <article class="card-file">
                                <div style="margin-bottom: -6px">
                                    <iframe :src="content.video" class="video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div class="ml-4 box-content">
                                    <div>
                                        <h5 class="card-title mt-4 mb-0 mr-4">
                                            {{content.title}}
                                        </h5>
                                        <p v-html="content.description" class="mt-3 mr-4" />
                                    </div>
                                </div>
                            </article>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-container>
    </section>
</template>
<script>
import DownloadIcon from '../utility/icons/DownloadIcon';

export default {
    name: 'MaterialsForDownload',
    props: ['payload'],
    components: {
        DownloadIcon
    },
    data() {
        return {
            index: 0
        }
    },
}
</script>
<style scoped lang="scss">

section {
    margin-bottom: 104px;
    margin-top: 97px;

    .video-heading-big {
        margin-top: 192px;
    }

    .video-heading-small {
        margin-bottom: 48px;
    }

    .card-file {
        display: flex;
        background-color: #FCFCFC;
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.08), -1px 2px 6px rgba(0, 0, 0, 0.1);

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .image {
            width: 460px;
            height: 280px;
            min-height: 100%;
            object-fit: cover;

            @include media-breakpoint-down(lg) {
                min-height: 100%;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                height: 350px;
                object-fit: cover;
            }
        }

        .video {
            width: 460px;
            height: 280px;
            min-height: 98%;

            @include media-breakpoint-down(lg) {
                min-height: 300px;
                height: 98.5%;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                height: 350px;
                object-fit: cover;
            }
        }

        .card-title {
            font-size: 24px;
        }

        .box-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            .box-icon {
                display: flex;
                justify-content: flex-end;
            }
        }

        .download-icon {
            margin: 0 24px 24px 0;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

</style>
