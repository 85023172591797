<template>
    <section cllass="contact-block">
        <b-container>
          <form v-if="!formSent" class="row" @submit="handleFormSubmit">
              <b-col class="text-center" sm="12" lg="6" offset-lg="1">
                <h3 class="mb-5" v-html="payload.title"></h3>
                <div v-html="payload.content"></div>
                <b-input req v-model="form.first_name" placeholder="Imię" required class="mt-5"/>
                <b-input req v-model="form.last_name" placeholder="Nazwisko" required class="mt-3"/>
                <b-input req v-model="form.email" placeholder="Adres e-mail" type="email" required class="mt-3"/>
                <b-input req v-model="form.phone" placeholder="Numer telefonu" class="mt-3"/>
                <b-button variant="secondary" class="mt-5" type="submit">Wyślij</b-button>
              </b-col>
          </form>
          <b-row v-if="formSent">
            <b-col class="text-center" sm="12" lg="6" offset-lg="1">
              Dziękujemy za kontakt, postaramy się odpowiedzieć tak szybko jak to możliwe.
            </b-col>
          </b-row>
        </b-container>
    </section>
</template>

<script>
import wp_api_client from "@/store/wp_api_client"; wp_api_client
const api = new wp_api_client(process.env.VUE_APP_API_URL)

    export default {
      name: "Contact",
      props: ['payload'],
      data(){
        return {
          form: {
            first_name: null,
            last_name: null,
            email: null,
            phone: null,
          },
          formSent: false
        }
      },
      methods: {
        handleFormSubmit(e) {
          e.preventDefault()
          api.sendContactForm(this.form)
            .then(() => {
              this.formSent = true
            })
        }
      }
    }
</script>

<style scoped lang="scss">
    section {
        background-color: #BDD4EF;
        padding-top: 6rem;
        padding-bottom: 6rem;
        @media (min-width: 1500px) {
          background: #BDD4EF url("../../assets/media/images/blue-bg-el.svg") center right no-repeat ;
        }
        @media (min-width: 992px) and (max-width: 1499px) {
          background: #BDD4EF url("../../assets/media/images/blue-bg-el.svg") center right -250px no-repeat ;
        }
    }
    input {
      background-color: $white;
      padding: 16px;
      &::placeholder{
        color: #979797;
      }
    }
</style>