<template>
    <section class="mb-8">
        <b-container>
            <b-row fluid>
                <b-col class="mt-8">
                    <h3 class="mb-2 mb-lg-2 text-center">Nasi Trenerzy</h3>
                </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col cols="12" lg="10">
                <div class="video-listing-item">
                  <iframe :src="payload.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </b-col>
            </b-row>
            <b-row cols="12" class="align-items-center pb-6 flex-column flex-lg-row justify-content-center">
                    <b-col xs="12" lg="6" xl="7" order="2" order-lg="1" v-html="payload.coaches[activeIndex].content" class="mt-5 mt-lg-4 text-center coach-bio">
                    </b-col>
                    <b-col xs="12" lg="6" xl="5" order="1" order-lg="2" class="d-flex align-items-center flex-column justify-content-center overflow-hidden">
                        <div class="mb-5 circle-outside justify-content-center" :style="'transform: rotate('+(framePosition * 90)+'deg)'">
                            <div class="circle-inside d-flex flex-column justify-content-center">
                                <div class="text-center" :style="'transform: rotate('+(framePosition * -90)+'deg)'">
                                    <div class="circle">
                                        <b-img class="circle__photo" :src='payload.coaches[activeIndex].image.url' alt="Coach image"></b-img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <b-row align-v="center" class="w-75 d-flex flex-nowrap justify-content-between">
                            <svg @click="decrementIndex" width="30" height="33" viewBox="0 0 25 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.3125 50.375L1.75 26L23.3125 1.625" stroke="#1B1718" stroke-width="5" stroke-linejoin="round"/>
                            </svg>
                            <h3 class="mb-0 px-4 mx-2 mx-lg-0 text-center coaches__name">{{payload.coaches[activeIndex].fullname}}</h3>
                            <svg @click="incrementIndex" width="30" height="33" viewBox="0 0 25 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.6875 1.625L23.25 26L1.6875 50.375" stroke="#1B1718" stroke-width="5" stroke-linejoin="round"/>
                            </svg>
                        </b-row>
                    </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
    name: "OurCoaches",
    props: ['payload'],
    data() {
        return {
            activeIndex: 0,
            framePosition: 0,
        }
    },
    methods: {
        incrementIndex() {
            this.activeIndex === this.payload.coaches.length - 1 ? this.activeIndex = 0 : this.activeIndex++;
            this.framePosition++
        },
        decrementIndex() {
            this.activeIndex === 0 ? this.activeIndex = this.payload.coaches.length - 1 : this.activeIndex--;
            this.framePosition--
        }
    }
}
</script>

<style scoped lang="scss">

.coach-bio::v-deep ul {
    padding-left: 0px;
    list-style-type: none;
}

svg {
    cursor: pointer;
    min-width: 30px;
}

.circle {
    width: 220px;
    height: 220px;
    @include circle-properties;

    &__photo {
        height: 100%;
        object-position: -50px 0px;
    }
}

.circle-outside {
    width: 280px;
    height: 280px;
    @include circle-outside-properties;

    > * > * {
        transition: transform 1s ease;
    }
}

.circle-inside {
    $inside-border-size: 17px;
    transform: translateX($inside-border-size) translateY($inside-border-size);
    width: calc(100% - #{$inside-border-size} * 2);
    height: calc(100% - #{$inside-border-size} * 2);
    border-radius: 100%;
    background: $white;
}

@include media-breakpoint-down(sm) {
    .coaches__name {
        font-size: 1.2rem;
    }
}

@include media-breakpoint-up(lg) {
    .circle {
        width: 310px;
        height: 310px;
        @include circle-properties;

        &__photo {
            height: 100%;
            object-position: -50px 0px;
        }
    }

    .circle-outside {
        width: 420px;
        height: 420px;
        @include circle-outside-properties;

        > * > * {
            transition: transform 1s ease;
        }
    }

    .circle-inside {
        $inside-border-size: 30px;
        transform: translateX($inside-border-size) translateY($inside-border-size);
        width: calc(100% - #{$inside-border-size} * 2);
        height: calc(100% - #{$inside-border-size} * 2);
        border-radius: 100%;
        background: $white;
    }
}

@include media-breakpoint-up(xl) {
    .circle {
        width: 360px;
        height: 360px;
        @include circle-properties;

        &__photo {
            height: 100%;
            object-position: -50px 0px;
        }
    }

    .circle-outside {
        width: 470px;
        height: 470px;
        @include circle-outside-properties;

        > * > * {
            transition: transform 1s ease;
        }
    }
}

.video-listing-item{
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  margin: 74px auto 96px auto;

  iframe{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

</style>
