<template>
    <section>
        <b-container>
            <b-row>
              <b-col class="text-center mb-5">
                <h3>{{ payload.title }}</h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="3" lg="3" class="text-center" v-for="(icon, index) in payload.icons" :key="index">
                <div>
                  <img v-if="icon.icon === 'heart'" src="../../assets/media/icons/heart.svg"/>
                  <img v-if="icon.icon === 'shield'" src="../../assets/media/icons/shield.svg"/>
                  <img v-if="icon.icon === 'plant'" src="../../assets/media/icons/plant.svg"/>
                  <img v-if="icon.icon === 'integration'" src="../../assets/media/icons/integration.svg"/>
                </div>
                <h6 class="mb-4 mb-md-0">{{ icon.name }}</h6>
              </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "Icons",
        props: ['payload']
    }
</script>

<style scoped lang="scss">
    img {
      max-width: 150px;
    }

    h6 {
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      margin-top: 2rem;
    }

    section {
      margin-top: 5rem;
      margin-bottom: 5rem;

      @include media-breakpoint-up(md){
        margin-top: 12rem;
        margin-bottom: 12rem;
      }
    }
</style>
