<template>
    <section>
        <b-container>
            <b-row>
                <b-col class="text-center">
                    <h3 v-if="payload.title">{{ payload.title }}</h3>
                </b-col>
            </b-row>
            <div v-if="payload.content.length > 0">
                <b-row v-for="(content, index) in payload.content" :key="index" class="my-6 my-lg-8">
                    <b-col class="col-12 col-lg-5" :class="[content.location === 'left' ? '' : 'order']">
                        <b-row :class="[content.location === 'right' ? ' justify-content-center justify-content-lg-end' : 'justify-content-center justify-content-lg-start']">
                            <div>
                                <div class="mb-4 mx-xl-2 circle-outside align-items-center justify-content-center justify-content-md-between" :style="'transform: rotate('+(index * 90)+'deg)'">
                                    <div class="circle-inside d-flex flex-column justify-content-center">
                                        <div class="text-center" :style="'transform: rotate('+(index * -90)+'deg)'">
                                            <div class="circle">
                                                <b-img class="circle__photo" :src='content.img.url' alt="Zdjęcie jury"></b-img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 class="mb-6 mb-lg-0 pt-3 px-4 mx-2 text-center coaches__name">{{content.name}}</h3>
                            </div>
                        </b-row>
                    </b-col>
                    <b-col class="col-12 col-lg-7 mb-0 mb-lg-4" align-self="center">
                        <div v-html="content.description"></div>
                    </b-col>
                </b-row>
            </div>
        </b-container>
    </section>
</template>
<script>
export default {
    name: 'FancyCircleWithImageDescription',
    props: ['payload'],
    data() {
        return {
            framePosition: 0,
            activeOrder: true,
        };
    },
};
</script>
<style scoped lang="scss">

section {
  margin-bottom: 104px;
  margin-top: 97px;

  .order {
    order: 1;

    @include media-breakpoint-down(md) {
        order: 0;
    }
  }

  .circle {
    width: 220px;
    height: 220px;
    @include circle-properties;

        &__photo {
            height: 260px;
            width: 260px;
            object-position: -60px 0px;
            object-fit: cover;
        }
    }

    .circle-outside {
        width: 280px;
        height: 280px;
        @include circle-outside-properties-blue;

        > * > * {
            transition: transform 1s ease;
        }
    }

    .circle-inside {
        $inside-border-size: 17px;
        transform: translateX($inside-border-size) translateY($inside-border-size);
        width: calc(100% - #{$inside-border-size} * 2);
        height: calc(100% - #{$inside-border-size} * 2);
        border-radius: 100%;
        background: $white;
    }

    @include media-breakpoint-down(sm) {
        .coaches__name {
            font-size: 1.2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .circle {
            width: 270px;
            height: 270px;
            @include circle-properties;

            &__photo {
                height: 300px;
                width: 300px;
                object-position: -70px 0px;
                object-fit: cover;
            }
        }

        .circle-outside {
            width: 370px;
            height: 370px;
            @include circle-outside-properties-blue;

            > * > * {
                transition: transform 1s ease;
            }
        }

        .circle-inside {
            $inside-border-size: 25px;
            transform: translateX($inside-border-size) translateY($inside-border-size);
            width: calc(100% - #{$inside-border-size} * 2);
            height: calc(100% - #{$inside-border-size} * 2);
            border-radius: 100%;
            background: $white;
        }
    }

    @include media-breakpoint-up(xl) {
        .circle {
            width: 380px;
            height: 380px;
            @include circle-properties;

            &__photo {
                height: 390px;
                width: 440px;
                object-position: -70px 0px;
                object-fit: cover;
            }
        }

        .circle-outside {
            width: 490px;
            height: 490px;
            @include circle-outside-properties-blue;

            > * > * {
                transition: transform 1s ease;
            }
        }
    }
}

</style>