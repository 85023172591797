<template>
  <svg @click="onClick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1"
       stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
</template>

<script>
export default {
  props: ['onClick'],
  name: "ChevronRight"
}
</script>

<style scoped>

</style>