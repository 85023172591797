<template>
  <b-row tag="li" class="pb-4 mt-6 mx-3" :class="activeClass">
    <b-col>
      <b-row>
        <b-col cols="12" lg="4" class="pl-0">
          <h5 class="text-center text-lg-left text-black">{{ noNbsp(payload.title) }}</h5>
        </b-col>
        <b-col class="text-center mt-4 mt-lg-0 px-4">
          <p>{{ noNbsp(payload.members) }}</p>
          <a :href="payload.link" target="_blank">{{ noNbsp(payload.school) }} ({{ payload.region }})</a>
        </b-col>
          <b-col class="d-flex justify-content-center mt-6" order-lg="4" sm="12" lg="12" cols="12" v-if="isExpanded && payload.youtube">
              <div class="video">
                  <iframe width="560" height="315" :src="payload.youtube" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
          </b-col>
        <b-col cols="12" tag="p" v-if="isExpanded" class="m-0 mt-6 text-center text-lg-left" order-lg="5">
          {{ payload.description }}
        </b-col>
<!--          <b-row class="justify-content-center mb-4 text-center" v-if="payload.youtube">-->

<!--          </b-row>-->
        <b-col cols="12" lg="1" class="button mt-4 mt-lg-0  pr-0 d-flex justify-content-end">
          <div v-show="isHovered && !isExpanded" class="align-items-center" @mouseleave="isHovered = false">
            <p class="m-0 mr-2">O zespole</p>
            <img alt="chevron-right-icon" @click="isExpanded=true" role="button"
                 src="../../assets/media/icons/chevron-right.svg"/>
          </div>
          <img alt="plus-outlined-icon" v-show="!isHovered && !isExpanded" @mouseover="isHovered = true"
               src="../../assets/media/icons/plus-outlined.svg"/>
          <img alt="minus-outlined-icon" v-show="!isHovered && isExpanded" @mouseover="isHovered = true"
               src="../../assets/media/icons/minus-outlined.svg"/>
          <img alt="plus-solid-icon" v-show="isHovered && isExpanded" @mouseleave="isHovered = false"
               @click="isExpanded=isHovered=false" role="button"
               src="../../assets/media/icons/plus-solid.svg"/>
        </b-col>

      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ExpandableItem",
  props: ['payload', 'colorIndex'],
  data() {
    return {
      isExpanded: false,
      isHovered: false,
    }
  },
  methods: {
    noNbsp(text) {
      const reg = new RegExp(String.fromCharCode(160), "g");
      return text.replace(reg,' ');
    }
  },
  computed: {
    activeClass() {
      switch (this.colorIndex) {
        case 0:
          return 'active-health';
        case 1:
          return 'active-safety';
        case 2:
          return 'active-climate';
        case 3:
          return 'active-integration';
        default:
          return {};
      }
    }
  }
}
</script>

<style scoped lang="scss">
.active {
  &-health {
    border-bottom-color: $health-red;
  }

  &-safety {
    border-bottom-color: $safety-yellow;
  }

  &-climate {
    border-bottom-color: $climate-blue;
  }
  &-integration {
    border-bottom-color: $integration-gold;
    & * {
      color: black;
    }
  }
}

.button {
  min-width: 10rem;

  & * {
    display: flex;
  }

  & p {
    white-space: nowrap;
  }
}

img {
  width: 2.3rem;
}

a {
  color: inherit;
  text-decoration: underline;
}

li {
  border-bottom: solid 1px black;
}

.video {
    width: 560px;
    height: 315px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 580px) {
        width: 300px;
        height: 200px;
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}
</style>
