<template>
  <div>
    <div class="box-image">
      <img class="main-image" :src="mainImage" alt="Zdjęcie konkursu" />
<!--      <h1 class="main-header">Zaproś młodych do budowania<br /> lepszego jutra</h1>-->
    </div>
    <div  ref="targetRef"></div>
    <section>
      <form>
        <b-container>
          <div v-if="!sendForm">
            <b-row>
              <b-col class="text-center">
                <h3>Jesteś zainteresowany, zainteresowana udziałem w programie
                  Solve for Tomorrow? Chcesz otrzymywać na bieżąco informacje
                  o naszych działaniach i dodatkowe materiały przydatne podczas lekcji?
                  <br /><br />
                  Wypełnij krótki formularz zgłoszeniowy.
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="6" offset-lg="3">
                <b-row>
                  <b-col cols="12">
                    <h4 class="form-section-title yellow">Informacje o Tobie</h4>
                    <b-row>
                      <b-col cols="12">
                        <div class="input-box">
                          <div class="input-box">
                            <b-input class="mb-4 input" required placeholder="Nazwa placówki *" v-model="form.school_name"></b-input>
                            <p>Nazwa placówki <span class="color">*</span></p>
                          </div>
                          <b-select class="mt-4 input select" required :options="voivodeships" v-model="form.school_voivodeship">
                            <template #first>
                              <b-select-option :value="null" class="select" disabled>Województwo *</b-select-option>
                            </template>
                          </b-select>
                          <div class="input-box">
                            <b-input class="mb-4 input" placeholder="Imię i nazwisko *" v-model="form.first_name"></b-input>
                            <p>Imię i nazwisko <span class="color">*</span></p>
                          </div>
                          <div class="input-box">
                            <b-input class="mb-4 input" placeholder="Adres e-mail *" required type="email" v-model="form.email"></b-input>
                            <p>Adres e-mail <span class="color">*</span></p>
                          </div>
                          <div class="input-box">
                            <b-input class="mb-4 input" placeholder="Numer telefonu" v-model="form.phone"></b-input>
                            <p>Numer telefonu</p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h4 class="mt-5 mb-4 text-center">Regulamin</h4>
                    <label class="input-control mb-3">
                      <input type="checkbox" required v-model="form.regulation_consent" />
                      <p class="mb-0">Oświadczam, że zapoznałem/zapoznałam się z <a href="https://solvefortomorrow.pl/wp-content/uploads/2023/09/regulamin_Solve_for_Tomorrow_PL_3_edycja.pdf" target="_blank">regulaminem</a> programu. <span class="color-span">*</span></p>
                    </label>
                    <h4 class="mt-5 mb-4 text-center">Zgody</h4>
                    <label class="input-control mb-3">
                      <input type="checkbox" required v-model="form.personal_data_consent" />
                      <p class="mb-0">Wyrażam zgodę na przetwarzanie moich danych osobowych oraz wizerunku w celach związanych z udziałem w programie Solve for Tomorrow.<span class="color-span">*</span></p>
                    </label>
                  </b-col>
                </b-row>
                <b-row v-if="validForm">
                  <b-col class="text-center my-5 error">
                    <h3>Wszystkie pola formularza oznaczone * oraz zgody muszą być wypełnione lub zaznaczone</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col v-if="!sendForm" class="mt-5 mb-3 d-inline-flex justify-content-center">
                    <b-button @click="handleFormSubmit" class="ml-4" type="submit" variant="primary">Wyślij</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col class="text-center my-5" v-if="!errorAlert && sendForm">
              <h3>
                Dziękujemy za wypełnienie formularza. <br />
                Na maila wysłaliśmy potwierdzenie i link do formularza zgłoszeniowego dla zespołu. <br />
                W razie nieotrzymania wiadomości, prosimy o sprawdzenie folderu SPAM lub kontakt z nami.
              </h3>
            </b-col>
            <b-col class="text-center my-5" v-if="errorAlert && sendForm">
              <h3>
                Ups... Coś poszło nie tak!<br />
                Spróbuj ponownie później!
              </h3>
            </b-col>
          </b-row>
          <b-col v-if="sendForm" class="mt-5 mb-3 d-inline-flex justify-content-center">
            <router-link to="/"><b-button variant="primary">Wróć na stronę główną</b-button></router-link>
          </b-col>
        </b-container>
      </form>
    </section>
  </div>
</template>

<script>
import mainImage from '../../assets/media/images/form-app-image.jpg'
import wp_api_client from "@/store/wp_api_client";
const api = new wp_api_client(process.env.VUE_APP_API_URL)

const voivodeships = [
  'dolnośląskie',
  'kujawsko-pomorskie',
  'lubelskie',
  'lubuskie',
  'łódzkie',
  'małopolskie',
  'mazowieckie',
  'opolskie',
  'podkarpackie',
  'podlaskie',
  'pomorskie',
  'śląskie',
  'świętokrzyskie',
  'warmińsko-mazurskie',
  'wielkopolskie',
  'zachodniopomorskie',
];

const form = {
  school_name: null,
  school_voivodeship:null,
  first_name: null,
  email: null,
  phone: null,
  regulation_consent: false,
  personal_data_consent: false,
};

export default {
  name: "RegisterStarterForm",
  data() {
    return {
      form: form,
      mainImage: mainImage,
      voivodeships: voivodeships,
      activeIndex: 0,
      errorAlert: false,
      sendForm: false,
      validForm: false,
      formTimeout: null
    }
  },
  methods: {
    handleFormSubmit(e) {
      e.preventDefault();
      if(this.validateFormValues("phone") && !this.errorAlert) {
        api.sendApplicationStarterForm(this.form)
            .then((res) => {
              if(res.status !== 200) {
                this.errorAlert = true
                this.sendForm = true;
                this.$refs.targetRef.scrollIntoView({ block: 'start', behavior: 'smooth' });
              } else {
                this.sendForm = true;
                this.form = {
                  school_name: null,
                  school_voivodeship:null,
                  first_name: null,
                  email: null,
                  phone: null,
                  regulation_consent: null,
                  personal_data_consent: null,
                }
                this.$refs.targetRef.scrollIntoView({ block: 'start', behavior: 'smooth' });
              }
            }).catch(() => {
          this.sendForm = true;
          this.errorAlert = true
          this.validForm = false;
        })
      }
    },
    validateFormValues(excludeField) {
      for (const key in this.form) {
        if (key !== excludeField) {
          const value = this.form[key];
          if (value === null || value === undefined || value === false || value === "") {
            this.validForm = true;

            if (this.formTimeout !== null) {
              clearTimeout(this.formTimeout);
            }

            this.formTimeout = setTimeout(() => {
              this.validForm = false;
            }, 4000);

            return false;
          }
        }
      }
      return true;
    }
  }
}
</script>

<style scoped lang="scss">
section {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.box-image {
  position: relative;
}
.main-image {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
.main-header {
  position: absolute;
  top: 50%;
  left: 13%;
  transform: translateY(-50%);
  font-weight: 700;
  color: white;
  font-size: 48px;
  @include media-breakpoint-down(sm) {
    left: 2%;
    font-size: 32px;
  }
}
.link-statute {
  color: black;
  text-decoration: underline;
}
.team-size-btn {
  width: 120px;
  text-align: center;
  cursor: pointer;
  border: 2px solid black;
  border-radius: 20px;
  padding: 16px;
  &.active {
    border-color: #A18037;
  }
}
label{
  position: relative;

  textarea:focus + .maxlength-label {
    color: #C50000;
  }

  .maxlength-label{
    position: absolute;
    right: 16px;
    bottom: 8px;
    //color: #C50000;
  }
}
.form-type-buttons {
  margin-top: 6rem;
  margin-bottom: 6rem;
  button {
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: lighter;
  }
}
.form-section-title{
  text-align: center;
  margin-top: 7rem;
  margin-bottom: 3rem;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    width: 100%;
    height: 3px;
  }
  &.yellow {
    &:after {
      background-color: #FCC157;
    }
  }
  &.blue {
    &:after {
      background-color: #007CC2;
    }
  }
  &.red {
    &:after {
      background-color: #FF4337;
    }
  }
}
.hr_container {
  position: relative;
  &:after {
    position: absolute;
    top:50%;
    display: block;
    content: ' ';
    width: 100%;
    height: 1px;
    background: $black;
  }
}
.number {
  background: $white;
  position: relative;;
  z-index: 1;
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: #C5C5C5;
  border: 1px solid #C5C5C5;
  border-radius: 100%;
  font-family: Samsung Sharp Sans;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  &.active{
    color: $black;
    border-color: $black;
  }
  &.finished{
    color: #878424;
    border-color: #878424;
  }
}
.form-control, .custom-select {
  padding: 16px;
  background-color: $white;
  border: 1px solid #59595C;
  &::placeholder{
    color: #979797;
  }
}
.input {
  border-radius: 10px;
}

.label {
  font-size: 16px;
  font-weight: 500;
  font-family: "Samsung Sharp Sans";
}

.select {
  font-weight: 500;
  font-family: "Samsung Sharp Sans";

  & option:first-child {
    color: #979797;
  }
}

.box {

  .active {
    svg path {
      fill: #A18037
    }
  }
}

.input-control {
  display: flex;
  align-items: center;
  margin-top: 40px;
  gap: 16px;
  font-size: 18px;
  font-family: "Samsung Sharp Sans";

  & div {
    display: flex;
  }

  & a {
    color: black;
    text-decoration: underline;
  }

  &.img {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: #000;
    width: 25px;
    height: 25px;
    border: 1px solid black;
    border-radius: 0.15em;
    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 25px;
      height: 25px;
    }

    &:checked::before {
      border-radius: 0.15em;
      background: black no-repeat center url("../../assets/media/images/mark.svg");
    }
  }
}

.star-box {
  position: relative;
}

.star {
  position: absolute;
  top: 40%;
  right: -2%;
  font-weight: 700;
  font-size: 17px;
  font-family: "Samsung Sharp Sans";
}

.input-box {
  position: relative;
  margin-top: 48px;

  & p {
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: 400;
    font-family: "Samsung Sharp Sans";
    color: #979797;
    display: none;

    & .color {
      color: #C50076;
    }
  }

  & input:focus + p, & input:not(:placeholder-shown) + p  {
    display: block;
    top: -22%;
  }

  & textarea:focus + p, & textarea:not(:placeholder-shown) + p {
    display: block;
    top: -18%;
  }

  & input:focus::placeholder, & textarea:focus::placeholder {
    opacity: 0;
  }
}

.color-span {
  color: #C50076;
}

.link {
  color: black;
  text-decoration: underline;

  &:visited, &:hover {
    color: black;
  }
}
.error {
  color: #df2019;
}
</style>
