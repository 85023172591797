<template>
  <section>
    <b-container class="register_exc_section">
      <b-row>
        <b-col cols="12" xl="5" offset-xl="1">
          <!-- <b-row v-if="isApplicationOpen">
            <b-col class="text-center mt-4">
              <h4>Zapisz się do programu</h4>
            </b-col>
          </b-row>
          <b-row v-if="isApplicationOpen">
            <b-col class="text-center mt-3">
              <router-link to="/formularz">
                <b-button variant="dark-yellow button-application">Aplikuj</b-button>
              </router-link>
            </b-col>
          </b-row>
          <b-row v-if="isApplicationOpen">
            <b-col class="text-center mt-3 box-link">
              <a class="link" href="https://solvefortomorrow.pl/wp-content/uploads/2022/09/regulamin_Solve_for_Tomorrow_PL_20_09_2022_mini.pdf"
                 target="_blank">Regulamin</a>
            </b-col>
          </b-row> -->
          <b-row v-if="isApplicationOpen">
            <b-col class="text-center mt-4 box-link">
              <h4>Najbliższe wydarzenia</h4>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col class="text-center" :class="{'my-3':!isApplicationOpen, 'my-xl-8':!isApplicationOpen, 'mt-0':
            isApplicationOpen}">
              <h4>Jeśli będziemy organizować spotkania dotyczące Solve for Tomorrow tu znajdziesz o nich informacje</h4>
            </b-col>
          </b-row> -->
          <!-- <b-row>
            <b-col class="text-center" :class="{'my-3':!isApplicationOpen, 'my-xl-8':!isApplicationOpen, 'mt-5':
            isApplicationOpen, 'mb-4':isApplicationOpen}">
              <p class="paragraph-meeting">Brak dostępnych spotkań</p>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col class="text-center" :class="{'my-3':!isApplicationOpen, 'my-xl-8':!isApplicationOpen, 'mt-2':
            isApplicationOpen, 'mb-4':isApplicationOpen}">
              <p class="heading-meeting">19.10.2022 r., godz. 11.00-12.00</p>
              <p class="heading-meeting link"><a href="https://edukacja.clickmeeting.com/solve-for-tomorrow" target="_blank">Spotkanie informacyjno-inspiracyjne o programie Solve for Tomorrow dla bibliotek</a></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center" :class="{'my-3':!isApplicationOpen, 'my-xl-8':!isApplicationOpen, 'mt-2':
            isApplicationOpen, 'mb-4':isApplicationOpen}">
              <p class="heading-meeting">22.10.2022 r., godz. 10.00-12.00</p>
              <p class="heading-meeting link"><a href="https://edukacja.clickmeeting.com/solve-for-tomorrow" target="_blank">Warsztat online: Jak wspierać pracę projektową w Laboratoriach Przyszłości (spotkanie realizowane w partnerstwie z Centrum GovTech)</a></p>
            </b-col>
          </b-row>
          <b-row v-if="meetingDate">
            <b-col class="text-center mt-3">
              <a href="mailto:kontakt@solvefortomorrow.pl?subject=Udział%20w%20spotkaniu%20informacyjnym%2005.10.2021r.&body=Dzień%20dobry,%20Jest">
                <b-button variant="yellow">Weź udział w spotkaniu</b-button>
              </a>
            </b-col>
          </b-row>
          <b-row v-if="meetingDate">
            <b-col class="text-center mt-3">
              <h5>{{ meetingDate }}</h5>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" xl="5" class="d-flex mt-5 justify-content-center justify-content-xl-end align-items-end">
          <social-media dark/>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import SocialMedia from "@/components/footer/SocialMedia";

export default {
  name: "RegisterFormExcerpt",
  components: {SocialMedia},
  computed: {
    isApplicationOpen: function () {
      return true
    },
    meetingDate: function () {
      return ""
    },
  }

}
</script>

<style scoped lang="scss">
.register_exc_section {
  background: #FFE4B9;
  border-radius: 20px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (min-width: 1200px) {
    background: #FFE4B9 url("../../assets/media/images/yellow-bg-el.svg") center right no-repeat;
  }

  .box-link{
    margin-bottom: 50px;

    .link {
      color: black;
      text-decoration: underline;
    }
  }

  .button-application {
    font-weight: lighter;
    font-size: 18px;
    padding: 20px 62px;
  }

  .paragraph-meeting {
    font-weight: 500;
    font-size: 18px;
    color: #59595C;
  }

  .heading-meeting {
    font-weight: 600;
    font-size: 18px;
    color: black;

    &.link a, &.link a:visited, &.link a:active {
      text-decoration: underline;
      color: black;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

</style>
