<template>
    <svg width="57" height="48" viewBox="0 0 57 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6 16.9C27.5 16.9 27.4 16.8 27.3 16.7L23.3 12.7C22.9 12.3 22.9 11.7 23.3 11.3C23.7 10.9 24.3 10.9 24.7 11.3L27 13.6V2C27 1.4 27.4 1 28 1C28.6 1 29 1.4 29 2V13.6L31.3 11.3C31.7 10.9 32.3 10.9 32.7 11.3C33.1 11.7 33.1 12.3 32.7 12.7L28.7 16.7C28.65 16.75 28.6 16.775 28.55 16.8C28.5 16.825 28.45 16.85 28.4 16.9C28.3 17 28.1 17 28 17C27.9 17 27.7 17 27.6 16.9ZM38 20V17C38 16.4 37.6 16 37 16C36.4 16 36 16.4 36 17V20C36 20.6 35.6 21 35 21H21C20.4 21 20 20.6 20 20V17C20 16.4 19.6 16 19 16C18.4 16 18 16.4 18 17V20C18 21.7 19.3 23 21 23H35C36.7 23 38 21.7 38 20Z" fill="#202020"/>
    <mask id="mask0_2004_9007" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="18" y="1" width="20" height="22">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6 16.9C27.5 16.9 27.4 16.8 27.3 16.7L23.3 12.7C22.9 12.3 22.9 11.7 23.3 11.3C23.7 10.9 24.3 10.9 24.7 11.3L27 13.6V2C27 1.4 27.4 1 28 1C28.6 1 29 1.4 29 2V13.6L31.3 11.3C31.7 10.9 32.3 10.9 32.7 11.3C33.1 11.7 33.1 12.3 32.7 12.7L28.7 16.7C28.65 16.75 28.6 16.775 28.55 16.8C28.5 16.825 28.45 16.85 28.4 16.9C28.3 17 28.1 17 28 17C27.9 17 27.7 17 27.6 16.9ZM38 20V17C38 16.4 37.6 16 37 16C36.4 16 36 16.4 36 17V20C36 20.6 35.6 21 35 21H21C20.4 21 20 20.6 20 20V17C20 16.4 19.6 16 19 16C18.4 16 18 16.4 18 17V20C18 21.7 19.3 23 21 23H35C36.7 23 38 21.7 38 20Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_2004_9007)">
    <rect x="16" width="24" height="24" fill="#202020"/>
    </g>
    <path d="M0.588 45H2.492V42.074H4.074C6.202 42.074 7.77 40.45 7.77 38.42C7.77 36.404 6.202 34.78 4.088 34.78H0.588V45ZM3.948 36.544C5.068 36.544 5.852 37.37 5.852 38.42C5.852 39.47 5.068 40.31 3.948 40.31H2.492V36.544H3.948ZM14.0115 45.14C16.9235 45.14 19.2475 42.802 19.2475 39.89C19.2475 36.992 16.9235 34.64 14.0115 34.64C11.0995 34.64 8.78948 36.992 8.78948 39.89C8.78948 42.802 11.0995 45.14 14.0115 45.14ZM14.0115 43.264C12.1495 43.264 10.6795 41.752 10.6795 39.89C10.6795 38.028 12.1495 36.516 14.0115 36.516C15.8735 36.516 17.3575 38.028 17.3575 39.89C17.3575 41.752 15.8735 43.264 14.0115 43.264ZM20.34 45H23.812C26.066 45 27.48 43.992 27.48 41.976C27.48 40.842 26.948 40.058 25.996 39.624C26.738 39.19 27.116 38.518 27.116 37.636C27.116 35.746 25.842 34.78 23.56 34.78H20.34V45ZM23.728 40.632C25.142 40.632 25.562 41.094 25.562 41.934C25.562 42.774 24.974 43.25 23.812 43.25H22.244V40.632H23.728ZM23.56 36.53C24.736 36.53 25.198 36.866 25.198 37.636C25.198 38.364 24.778 38.854 23.56 38.854H22.244V36.53H23.56ZM28.6454 45H30.5494V34.78H28.6454V45ZM32.012 45H38.074V43.18H33.916V40.772H38.074V38.952H33.916V36.6H38.074V34.78H32.012V45ZM41.249 45V41.878H42.957L44.595 45H46.765L44.721 41.318C45.841 40.716 46.443 39.568 46.443 38.336C46.443 36.222 45.029 34.78 42.621 34.78H39.345V45H41.249ZM41.249 40.128V36.558H42.453C43.909 36.558 44.539 37.216 44.539 38.266C44.539 39.274 44.021 40.128 42.453 40.128H41.249ZM47.5785 45H54.3405V43.18H49.8185L54.3405 36.516V34.78H47.7325V36.6H52.1005L47.5785 43.25V45Z" fill="#202020"/>
    </svg>
</template>
<script>
    export default {

    }
</script>